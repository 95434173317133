import { render, staticRenderFns } from "./controlType.vue?vue&type=template&id=bceaf594&scoped=true&"
import script from "./controlType.vue?vue&type=script&lang=js&"
export * from "./controlType.vue?vue&type=script&lang=js&"
import style0 from "./controlType.vue?vue&type=style&index=0&id=bceaf594&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bceaf594",
  null
  
)

export default component.exports